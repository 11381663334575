.scroll-container {
  width: 40rem;
  position: absolute;
  bottom: 0;
  height: 35%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

.nav-menu {
  position: absolute;
  display: flex;
  bottom: 12rem;
  z-index: 1;
  /* padding: 0.7rem 0.6rem; */
  /* border-radius: 0.5rem; */
  /* background-color: rgb(20 20 20 / 70%); */
  width: 100%;
  left: 1rem;
}

.scroll-container {
  transition: transform 1s ease;
}


.nav-menu.hide {
  bottom: 2.6rem;
}

.nav-menu.show {
  bottom: 12rem;
}

.siteonly.nav-menu.show {
  bottom: 14.5rem;
}

.nav-menu-item-cont {
  margin: 0rem 1rem 0rem 0rem;
  /* border-radius: 0.5rem; */
  display: flex;
  /* border: 1px solid rgb(97 97 97); */
  cursor: pointer;
}

.sub-menu-item-cont {
  display: flex;
  background: #00000040;
  border-radius: 10rem;
  margin-left: 0.5rem;
  white-space: nowrap;
  opacity: 0;
}
.sub-menu-item-cont.visible {
  opacity: 1;
}
.sub-menu-item-div {
  margin-right: 0.5rem;
}

.sub-menu-item-div:last-child {
  margin-right: 0rem;
}

.nav-sub-menu-item-cont {
  border: none;
}

.nav-menu-item-overlay {
  /* background-color: rgb(0 0 0 / 40%); */
  backdrop-filter: blur(0.75px);
  min-width: 5rem;
  height: 2rem;
  position: absolute;
  z-index: 1;
  cursor: pointer;
  transition: background-color 0.3s ease;
  padding: 0.25rem 1rem;
  border-radius: 10rem;
  border: 1px solid white;
  background: rgb(81 81 81 / 10%);
  backdrop-filter: blur(2px);
}

.nav-sub-menu-item-overlay {
  /* background-color: rgb(0 0 0 / 0%); */
}

.nav-menu-item-overlay:hover {
  /* background-color: rgb(0 0 0 / 50%); */
  backdrop-filter: blur(0px);
  background: rgba(167, 167, 167, 0.1);
  backdrop-filter: blur(2px);
}

.nav-sub-menu-item-overlay:hover {
  background-color: rgb(0 0 0 / 0%);
}

.nav-menu-item {
  /* min-width: 5rem; */
  /* height: 2rem; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: inherit;
  border-radius: inherit;
  /* padding: 0.25rem 1rem; */
  white-space: nowrap;
}

.nav-menu-item-info {
  position: relative;
  display: flex;
  z-index: 3;
  pointer-events: none;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* margin-top: 0.3rem; */
  border: 1px solid white;
  background: rgb(81 81 81 / 10%);
  backdrop-filter: blur(2px);
  padding: 0.5rem 1.3rem;
  border-radius: 10rem;
}

.nav-sub-menu-item-info {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 3;
  pointer-events: none;
}

.nav-menu-item-title {
  color: white;
  z-index: 2;
  border-radius: inherit;
  pointer-events: none;
}

.nav-menu-item img{
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

#prev-btn {
  left: 1rem;
}

#next-btn {
  right: 1rem;
}

.scroll-container {
  width: 100%;
  left: unset;
  transform: unset;
}

.pre-ques-cont {
  position: relative;
  /* bottom: -9rem; */
  z-index: 1000000000001;
  left: 0;
  width: 100%;
  /* height: 100%; */
  margin-bottom: 0.1rem;
}


.pre-ques-cont > .scroll-container {
  position: relative !important;
  /* height: 100% !important; */
  width: 100% !important;
}

.pre-question-list {
  display: flex;
  white-space: nowrap;
  padding: 0rem 0rem 0rem 0.5rem;
  list-style: none;
  margin: 0;
}

.pre-question-list li{
  border: 1px solid rgba(143, 143, 143, 0.588);
  border-radius: 10rem;
  color: white;
  padding: 0.25rem 1rem;
  margin-right: 0.5rem;
  background-color: #00000030;
  backdrop-filter: blur(1px);
  cursor: pointer;
}

.play-btn-cont > img {
  /* border: 1px solid white; */
  /* border-radius: 5rem; */
  /* background: rgb(81 81 81 / 10%); */
  /* padding: 0.33rem; */
  /* backdrop-filter: blur(2px); */
  width: 1rem;
}

@media (min-width: 768px) {
  #prev-btn {
    left: 3rem;
  }
  
  #next-btn {
    right: 3rem;
  }
  .scroll-container {
    width: 38rem;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;
  }
  .siteonly.scroll-container {
    width: 50rem;
  }
  .play-btn-cont > img {
    /* border: 1px solid white; */
    /* border-radius: 5rem; */
    /* background: rgb(81 81 81 / 10%); */
    /* padding: 0.33rem; */
    /* backdrop-filter: blur(2px); */
    width: 1.1rem;
  }
}


.arrow-btn {
  position: absolute !important;
  top: 60% !important;
  left: 50% !important;
  opacity: 0.8;
  cursor: pointer;
  z-index: 1000000000 !important;
}

.arrow-btn:hover {
  opacity: 1;
}

.play-btn-cont {
  position: absolute;
  bottom: 12.05rem;
  z-index: 100;
  color: white;
  left: 1rem;
  border: 1px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5rem;
  padding: 0.415rem 0.415rem 0.415rem 0.48rem;
  background: rgb(81 81 81 / 10%);
  backdrop-filter: blur(2px);
  cursor: pointer;
}

.siteonly.play-btn-cont {
  bottom: 14.45rem;
}

.play-btn-cont.hide {
  bottom: 2.6rem;
}

.play-btn-cont.show {
  bottom: 12rem;
}

.siteonly.play-btn-cont.show {
  bottom: 14.45rem;
}

.scroll-container > .navigation-cont {
  left: 4rem;
}

a {
  text-decoration: none; /* Remove underline */
  color: inherit; /* Inherit text color */
  cursor: pointer; /* Add pointer cursor */
}

.nav-menu-item-info.active {
  background-color: #fff;
  border: 1px solid #fff487;
}

.nav-menu-item-title.active {
  color: black;
}